import { useMemo, useState, useEffect } from 'react';

import pick from 'lodash/pick';
import omit from 'lodash/omit';
import SVG from 'react-inlinesvg';
import startCase from 'lodash/startCase';
import Select, { OptionTypeBase } from 'react-select';

import { IFood, IRecipeFoodServing, TAny } from 'shared/src/types';

import NumInput from 'shared/src/components/web/NumInput';

import useServing, { ELEMENTS, TValueKey } from 'hooks/useServing';
import NUTRITIONAL_VALUES from 'shared/src/constants/nutritionalValues';

interface IngredientsInputProps {
  index: number;
  disabled: boolean;
  data: IRecipeFoodServing | IFood;
  onRemove: (id: string, index: number) => void;
  onChangeServing: (
    id: string,
    servingUnit: string,
    servingValue: number,
    servingConversions: Partial<IFood>[],
    index: number
  ) => void;
}
const IngredientInput = ({
  data,
  index,
  disabled,
  onRemove,
  onChangeServing,
}: IngredientsInputProps) => {
  const [sizeInput, setSizeInput] = useState(data.servingSize || 1);
  const [unitInput, setUnitInput] = useState<OptionTypeBase | null>();

  const { selectOptions, servingConversions } = useMemo(() => {
    const food = (data as IRecipeFoodServing).food ?? (data as IFood);
    let servingConversions: Partial<IFood>[] =
      food.servingConversions?.edges?.map(
        ({ node }: { node: Partial<IFood> }) => omit(node, ['id', 'objectId'])
      ) ?? [];

    if (servingConversions.length < 1) {
      let _data: TAny = data as IRecipeFoodServing;
      if (!_data.foodName && _data.food) {
        _data = _data.food;
      }
      servingConversions = [
        {
          ...pick(_data, [...NUTRITIONAL_VALUES.ALL, 'totalFat']),
          servingSize: data.servingSize ?? 1,
          servingUnit: data.servingUnit || 'servings',
        },
      ];
    }

    const selectOptions = servingConversions.map((conversion, index) => ({
      label: conversion.servingUnit,
      value: index,
    }));

    return {
      selectOptions,
      servingConversions,
      hasConversions: servingConversions.length > 1,
    };
  }, [data]);

  const ingredient = useMemo(() => {
    const servingSize = sizeInput;
    const servingUnit = unitInput?.label ?? '';
    const currentConversion = servingConversions.filter(
      (conversion) => conversion.servingUnit === servingUnit
    )[0];

    const food = (data as IRecipeFoodServing).food ?? (data as IFood);
    const item: TAny = {
      food: {
        ...food,
        fat: food.totalFat,
      },
      conversion: currentConversion,
    };

    item.servingSize = servingSize;
    item.servingUnit = servingUnit;
    item.baseServingSize = currentConversion?.servingSize ?? servingSize;

    return item;
  }, [data, sizeInput, unitInput]);

  const totals = useServing([ingredient], 1);

  useEffect(() => {
    const found = selectOptions.filter(
      ({ label }) => data.servingUnit === label
    )[0];

    found && setUnitInput(found);
    onChangeServing(
      data.id,
      found?.label ?? data.servingUnit ?? '',
      +sizeInput,
      servingConversions,
      index
    );
  }, []);

  const handleChangeInput = (value = 0) => {
    setSizeInput(value);
    onChangeServing(
      '',
      unitInput?.label ?? '',
      value,
      servingConversions,
      index
    );
  };

  const handleChangeSelect = (value: OptionTypeBase | null) => {
    setUnitInput(value);
    onChangeServing(
      '',
      value?.label ?? '',
      sizeInput,
      servingConversions,
      index
    );
  };

  const handleOnClickRemove = () => {
    onRemove(data.id ?? data.id, index);
  };

  return (
    <div className="form-group align-items-start">
      <div className="pr-4">
        <label className="d-block">
          {(data as IRecipeFoodServing)?.food?.name ??
            (data as IRecipeFoodServing)?.foodName}
        </label>
        {ELEMENTS.map((item) => {
          const title = startCase(item);
          const value = (totals[item as TValueKey] || 0).toFixed(2);
          return (
            <span
              key={`serving-table-item-${item}`}
              className="mr-1"
              style={{
                fontSize: 10,
                color: '#7E8299',
              }}>
              {`${title} ${value}gr`}
            </span>
          );
        })}
      </div>
      <div className="d-flex align-items-center">
        <NumInput
          value={sizeInput}
          disabled={disabled}
          onChange={handleChangeInput}
          className="form-control mr-2"
        />

        <div style={{ minWidth: 160 }}>
          <Select
            isDisabled={disabled}
            value={unitInput}
            options={selectOptions}
            onChange={handleChangeSelect}
          />
        </div>
        {!disabled && (
          <a className="pointer ml-auto" onClick={handleOnClickRemove}>
            <span className="svg-icon svg-icon-lg">
              <SVG src="/svg/icons/Navigation/Close.svg" />
            </span>
          </a>
        )}
      </div>
    </div>
  );
};

export default IngredientInput;
