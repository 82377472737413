import { useRef, useState, useEffect } from 'react';

import pick from 'lodash/pick';
import omit from 'lodash/omit';
import SVG from 'react-inlinesvg';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useLazyQuery } from '@apollo/client';
import { OptionTypeBase } from 'react-select';
import {
  Draggable,
  Droppable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';

import IngredientInput from 'components/ingredients/IngredientInput';

import { GET_ATLAS_FOODS_QUERY } from 'shared/src/graphql/food';
import { GET_ATLAS_RECIPES_QUERY } from 'shared/src/graphql/recipe';

import {
  TAny,
  IFood,
  IRecipe,
  TGraphQLList,
  TGraphQLResponse,
  IRecipeFoodServing,
} from 'shared/src/types';

import NUTRITIONAL_VALUES from 'shared/src/constants/nutritionalValues';

const baseVariables = {
  skip: 0,
  after: '',
  first: 100,
  order: 'isAtkinsAcceptable_DESC',
};

type Tcallback = (options: readonly TAny[]) => void;
export type IIngredientsProps = {
  name: string;
  type?: 'recipes';
  poolTime: number;
  disabled: boolean;
  placeholder: string;
  value: IRecipeFoodServingConversion[];
  onChange: (items: IRecipeFoodServing[]) => void;
};

let timeout: NodeJS.Timeout;

export interface IRecipeFoodServingConversion extends IRecipeFoodServing {
  order: number;
  conversion: Partial<IFood>;
}

const reorder = (
  list: IRecipeFoodServingConversion[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 2;
const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  width: '100%',
  borderRadius: 4,
  background: isDraggingOver ? 'rgba(0,0,0,0.05)' : 'none',
});

const getItemStyle = (draggableStyle?: TAny, isDragging?: boolean) => ({
  borderRadius: 4,
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  padding: grid * (isDragging ? 4 : 2),
  background: isDragging ? 'rgba(0,125,255,0.05)' : 'none',
  ...draggableStyle,
});

const Ingredients = ({
  name,
  type,
  value,
  disabled,
  onChange,
  placeholder,
  poolTime = 500,
}: IIngredientsProps) => {
  const refCallback = useRef<Tcallback | null>(null);

  const [getFoods, { data }] = useLazyQuery<
    TGraphQLResponse<'atlasFoods', TGraphQLList<IFood>>
  >(GET_ATLAS_FOODS_QUERY);

  const [getRecipes, { data: dataRecipes }] = useLazyQuery<
    TGraphQLResponse<'atlasRecipes', TGraphQLList<IRecipe>>
  >(GET_ATLAS_RECIPES_QUERY);

  const [conversionsMissing, setConversionsMissing] = useState<TAny[]>([]);

  const [ingredientList, setIngredientList] = useState<
    IRecipeFoodServingConversion[]
  >([]);
  const [selectValue, setSelectValue] = useState<OptionTypeBase | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const foods = data?.atlasFoods?.edges?.map(({ node }) => node) ?? [];
  const recipes =
    dataRecipes?.atlasRecipes?.edges?.map(({ node }) => node) ?? [];

  useEffect(() => {
    const _value: TAny[] = value ?? [];
    let toSet = _value;

    _value.sort((a, b) => a.order - b.order);

    toSet = _value.map((item: TAny, index) => {
      if (!item.includedRecipe) {
        if (item?.food?.objectId) {
          if (item.food?.servingConversions?.edges?.length === 0) {
            const newFood = cloneDeep(item.food);

            newFood.servingConversions = {
              edges: [],
            };

            item.food = newFood;
          }
          const conversions = item.food?.servingConversions?.edges;
          if (conversions?.length) {
            const prevMissing = conversionsMissing[index];

            if (prevMissing) {
              conversions.push({
                node: prevMissing,
              });
            } else {
              const found = conversions.filter(
                ({ node }: TAny) => node.servingUnit === item.servingUnit
              )[0];

              if (!found) {
                const missingItem = {
                  ...pick(item, [
                    'fiber',
                    'protein',
                    'totalFat',
                    'netCarbs',
                    'calories',
                    'totalCarbs',
                    'servingSize',
                    'servingUnit',
                  ]),
                };

                const _conversionsMissing = [...conversionsMissing];
                _conversionsMissing[index] = missingItem;
                setConversionsMissing(_conversionsMissing);

                conversions.push({
                  node: missingItem,
                });
              }
            }
          } else {
            if (conversions) {
              let _data: TAny = item as IRecipeFoodServing;
              if (!_data.foodName && _data.food) {
                _data = _data.food;
              }

              conversions.push({
                node: {
                  ...pick(_data, [...NUTRITIONAL_VALUES.ALL, 'totalFat']),
                  servingSize: item.servingSize ?? 1,
                  servingUnit: item.servingUnit || 'servings',
                },
              });
            }
          }
        }

        return {
          ...item,
          totalCarbs:
            item.totalCarbs ?? (item.netCarbs ?? 0) + (item.fiber ?? 0),
        };
      }

      return {
        ...omit(item, ['quantity', 'includedRecipe']),
        type: 'recipe',
        servingUnit: 'servings',
        servingSize: item.quantity ?? 1,
        baseServingSize: item.quantity ?? 1,
        food: {
          ...item.includedRecipe,
          servingUnit: 'servings',
          servingSize: 1,
          baseServingSize: 1,
        },
      };
    });

    setIngredientList(toSet);
  }, [value]);

  useEffect(() => {
    if (refCallback.current && !isEmpty(foods)) {
      const mapped = foods.map(
        ({
          id,
          name,
          netCarbs,
          servingUnit,
          servingSize,
          isAtkinsAcceptable,
        }) => ({
          name,
          value: id,
          servingUnit,
          servingSize,
          isAtkinsAcceptable,
          netCarbs: (netCarbs ?? 0).toFixed(2),
        })
      );

      refCallback.current(mapped);
    }
  }, [foods]);

  useEffect(() => {
    if (refCallback.current && !isEmpty(recipes)) {
      const mapped = recipes.map(({ id, name, netCarbs }) => ({
        name,
        value: id,
        servingSize: 1,
        servingUnit: 'servings',
        isAtkinsAcceptable: false,
        netCarbs: (netCarbs ?? 0).toFixed(2),
      }));

      refCallback.current(mapped);
    }
  }, [recipes]);

  const promiseOptions = (inputValue: string, callback: Tcallback) => {
    setIsLoading(true);

    if (timeout) {
      refCallback.current = null;
      clearTimeout(timeout);
    }

    if (isEmpty(inputValue)) {
      return;
    }

    timeout = setTimeout(() => {
      refCallback.current = callback;
      const action = type === 'recipes' ? getRecipes : getFoods;
      const variables = type === 'recipes' ? { first: 100 } : baseVariables;

      action({
        variables: {
          ...variables,
          where: {
            name: { text: { search: { term: inputValue } } },
          },
        },
      });
    }, poolTime);
  };

  const handleOnChange = (
    newValue: OptionTypeBase | null,
    { action }: { action: string }
  ) => {
    setSelectValue(newValue);
    let from: TAny = foods;
    if (type === 'recipes') {
      from = recipes;
    }

    if (action === 'select-option') {
      if (newValue) {
        const selectedFood = from.filter(
          ({ id }: { id: string }) =>
            id.toLowerCase() === newValue.value.toLowerCase()
        )[0];

        if (onChange) {
          const items = [
            ...ingredientList,
            {
              food: selectedFood,
              id: '',
              order: ingredientList.length,
              servingSize: selectedFood.servingSize ?? 1,
              servingUnit: selectedFood.servingUnit ?? 'servings',
              baseServingSize: selectedFood.servingSize ?? 1,
              baseServingUnit: selectedFood.servingUnit ?? 'servings',
            },
          ];

          onChange(items as IRecipeFoodServing[]);
          setSelectValue(null);
          setIsLoading(false);
        }
      }
    }
  };

  const handleOnBlur = () => {
    setIsLoading(false);
  };

  const handleOnRemoveItem = (foodId: string, index: number) => {
    const filtered = ingredientList.filter((_, _index) => index !== _index);
    if (onChange) {
      onChange([...filtered]);
    }
  };

  const handleOnChangeServing = (
    id: string,
    servingUnit: string,
    servingSize: number,
    servingConversions: Partial<IFood>[],
    index: number
  ) => {
    const item = ingredientList[index];

    const currentConversion = servingConversions.filter(
      (conversion) => conversion.servingUnit === servingUnit
    )[0];

    item.id = id;
    item.baseServingSize = currentConversion?.servingSize ?? servingSize;
    item.servingSize = servingSize;
    item.servingUnit = servingUnit;
    item.conversion = currentConversion;

    if (onChange) {
      onChange(ingredientList);
    }
  };

  const DropdownIndicator = (props: TAny) => {
    if (!isLoading) {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <span className="svg-icon svg-icon-lg svg-icon-primary">
              <SVG src="/svg/icons/General/Search.svg" />
            </span>
          </components.DropdownIndicator>
        )
      );
    }
    return <span />;
  };

  const { Option } = components;
  const IconOption = (props: TAny) => {
    const isAtkinsAcceptable: boolean = props.data.isAtkinsAcceptable;

    return (
      <Option {...props}>
        {isAtkinsAcceptable && (
          <img
            className="max-h-25px mr-4"
            alt="Logo"
            src="/svg/logos/atkins.svg"
          />
        )}
        <span>{props.data.name}</span>
        <span className="d-block">
          {props.data.servingSize} {props.data.servingUnit} /{' '}
          {props.data.netCarbs}g Net Carbs
        </span>
      </Option>
    );
  };

  const handleEndDrag = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newIngredientList = reorder(
      ingredientList,
      result.source.index,
      result.destination.index
    );

    newIngredientList.forEach((_, index) => {
      newIngredientList[index].order = index;
    });

    if (onChange) {
      onChange(newIngredientList);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    e.code === 'Enter' && e.preventDefault();
  };

  return (
    <div className="inline-field list-group">
      <DragDropContext onDragEnd={handleEndDrag}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}>
              {ingredientList.map((ingredient, index) => {
                if (!ingredient?.foodName && !ingredient?.food?.name) {
                  return null;
                }

                const id = ingredient.id ?? index.toString();

                return (
                  <Draggable
                    isDragDisabled={disabled}
                    key={id + index}
                    draggableId={id + index}
                    index={index}>
                    {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            provided.draggableProps.style,
                            snapshot.isDragging
                          )}>
                          <IngredientInput
                            index={index}
                            data={ingredient}
                            disabled={disabled}
                            onRemove={handleOnRemoveItem}
                            onChangeServing={handleOnChangeServing}
                            key={`food-ingredient-${ingredient?.id}`}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AsyncSelect
        name={name}
        className="select"
        value={selectValue}
        isDisabled={disabled}
        isLoading={isLoading}
        onBlur={handleOnBlur}
        classNamePrefix="select"
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
        placeholder={placeholder}
        loadOptions={promiseOptions}
        components={{ DropdownIndicator, Option: IconOption }}
      />
    </div>
  );
};

export default Ingredients;
